<template>
  <div class="home">
    <v-navigation-drawer
      v-model="drawer"
      width="250"
      persistent
      :mini-variant="miniVariant"
      :expand-on-hover="expandOnHover"
      class="overflow: auto; max-height: 100vh;"
      fixed
      app
    >
      <v-list nav>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class="title primary--text d-flex justify-left h1"
              >
                <span
                  ><img
                    alt="Vue logo"
                    :src="siteMode === 'UH' ? logoUH : logoVP"
                  />
                </span>
                {{ siteMode === 'UH' ? 'VPExam' : 'VPExam' }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list-item-group color="primary">
          <v-list-item to="/partner/appointments">
            <v-list-item-icon>
              <v-icon>mdi-stethoscope</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Appointments</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="$mq === 'mobile'">
            <v-list-item-content>
              <div class="pa-2">
                <v-btn color="error" outlined block @click="signOut()"
                  >Logout</v-btn
                >
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-if="$mq !== 'mobile'" v-slot:append>
        <div class="pa-2">
          <v-btn color="error" outlined block @click="signOut()">Logout</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <Appbar :user="currentUser">
      <template
        v-if="$mq === 'mobile' || $mq === 'tablet' || $mq === 'tabletlg'"
        v-slot:drawer
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </template>
    </Appbar>

    <v-main>
      <v-container fluid>
        <slot :user="currentUser"></slot>
      </v-container>
    </v-main>
    <!-- <FloatingActions style="position: fixed;" /> -->
  </div>
</template>

<script>
import { auth } from '@/firebase.js'
import Appbar from '@/partnerViews/components/Appbar'
// import FloatingActions from '@/components/FloatingActions'
import logoUH from '@/assets/logo.png'
import logoVP from '@/assets/logo.png'

export default {
  name: 'PartnerLayout',
  components: {
    Appbar
    // FloatingActions
  },
  props: ['user'],
  data() {
    return {
      logoUH: logoUH,
      logoVP: logoVP,
      siteMode: process.env.VUE_APP_SITE,
      fixed: false,
      drawer: true,
      miniVariant: false,
      expandOnHover: false,
      notificationCount: 3,
      currentUser: null
    }
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user) {
          this.currentUser = this.user
        }
      }
    }
  },
  created() {
    if (
      this.$mq === 'mobile' ||
      this.$mq === 'tablet' ||
      this.$mq === 'tabletlg'
    ) {
      this.drawer = false
    }
  },
  methods: {
    async signOut() {
      auth.signOut()
      this.$router.go()
    }
  }
}
</script>
