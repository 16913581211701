<template>
  <div class="appointments">
    <v-container>
      <div class="d-flex align-center">
        <h1>Upcoming Appointments</h1>
      </div>
    </v-container>

    <v-container>
      <v-sheet tile height="auto" class="d-flex pa-4">
        <v-toolbar flat>
          <v-btn fab text small color="grey darken-2" @click="prev()">
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>

          <v-toolbar-title v-if="$refs.calendar" style="min-width: 180px;">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-btn fab text small color="grey darken-2" @click="next()">
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-spacer></v-spacer>

          <v-menu v-if="$mq !== 'mobile'" bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <div class="ml-2" v-show="$mq !== 'mobile'">
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>Days</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="weekday = [0, 1, 2, 3, 4, 5, 6]">
                  <v-list-item-title>Sun - Sat</v-list-item-title>
                </v-list-item>
                <v-list-item @click="weekday = [1, 2, 3, 4, 5, 6, 0]">
                  <v-list-item-title>Mon - Sun</v-list-item-title>
                </v-list-item>
                <v-list-item @click="weekday = [1, 2, 3, 4, 5]">
                  <v-list-item-title>Mon - Fri</v-list-item-title>
                </v-list-item>
                <v-list-item @click="weekday = [1, 3, 5]">
                  <v-list-item-title>Mon, Wed, Fri</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600" class="pa-4 pt-0">
        <v-calendar
          v-model="value"
          ref="calendar"
          :weekdays="weekday"
          :type="$mq == 'mobile' ? 'day' : type"
          :events="events"
          :event-color="getEventColor"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :interval-count="24"
          color="primary"
          @click:event="showEvent"
          @click:more="setDayView"
        >
          <template v-slot:day-body="{ date, week }">
            <div
              class="v-current-time"
              :class="{ first: date === week[0].date }"
              :style="{ top: nowY }"
            ></div>
          </template>
        </v-calendar>
      </v-sheet>
    </v-container>

    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      top
    >
      <v-card color="grey lighten-4" min-width="300px" flat>
        <v-toolbar dark>
          <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="white" @click="selectedOpen = false">
            <v-icon>mdi-close-box-multiple-outline</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-card-text>
            <h3 v-if="patient.fname && patient.lname" class="headline mb-2">
              {{ patient.fname }} {{ patient.lname }}
            </h3>
            <div v-if="patient.email" class="primary--text mb-2">
              {{ patient.email }}
            </div>
            <div v-if="patient.phoneNumber" class="primary--text mb-2">
              {{ patient.phoneNumber }}
            </div>
            <div
              v-if="patient.primaryDoctor"
              class="primary--text subheading font-weight-bold"
            >
              Primary: {{ patient.primaryDoctor }}
            </div>
            <div
              v-if="patient.primaryDoctor !== selectedDoctor"
              class="primary--text subheading font-weight-bold"
            >
              Scheduled:
              {{ selectedDoctor }}
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-row class="text-left" tag="v-card-text">
            {{ computedStartTime }} - {{ computedEndTime }}
          </v-row>
          <v-row>
            <v-card-text>
              <v-btn
                @click="openOrder(selectedEvent.orderId)"
                block
                outlined
                color="primary"
                >View Order</v-btn
              >
            </v-card-text>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import PartnerLayout from '@/layouts/PartnerLayout'
import * as moment from 'moment'
import * as fb from '@/firebase'
export default {
  name: 'Upcoming',
  props: ['user'],
  data() {
    return {
      value: '',
      ready: false,
      viewHistoryDialog: false,
      deleteDialog: false,
      addAppointmentDialog: false,
      partnerId: '',
      mode: 'stack',
      type: 'week',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days'
      },
      weekday: [0, 1, 2, 3, 4, 5, 6],
      events: [],
      appointments: null,
      partner: {},
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      patient: {},
      selectedDoctor: ''
    }
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user) {
          this.partner = this.user
          this.$emit(`update:layout`, PartnerLayout)

          this.partnerId = this.partner.id
          this.$bind(
            'appointments',
            fb.db
              .collectionGroup(`Appointments`)
              .where('partnerId', '==', this.partner.id)
          )
        }
      }
    },
    appointments: {
      immediate: true,
      handler() {
        if (this.appointments) {
          this.events = []

          for (const item of this.appointments) {
            let color = 'primary'
            if (item.firstTime) {
              color = 'red'
            } else {
              color = 'green'
            }
            if (!item.adHoc) {
              fb.db
                .doc(`Patients/${item.patientId}`)
                .get()
                .then(patient => {
                  const name = `${
                    patient.data().fname ? patient.data().fname : ''
                  } ${patient.data().lname}`

                  this.events.push({
                    id: item.id,
                    name: name,
                    start: moment(item.startTime.toDate()).format(
                      'YYYY-MM-DD HH:mm'
                    ),
                    end: moment(item.endTime.toDate()).format(
                      'YYYY-MM-DD HH:mm'
                    ),
                    color: color,
                    ...item,
                    orderId: item.id
                  })
                })
            }
          }
        }
      }
    },
    selectedEvent: function() {
      this.getPatient()
      this.getDoctor()
    }
  },
  mounted() {
    this.$refs.calendar.checkChange()
    this.ready = true
    this.scrollToTime()
    this.updateTime()
  },
  computed: {
    cal() {
      return this.ready ? this.$refs.calendar : null
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },
    computedStartTime: {
      get() {
        return this.selectedEvent.startTime
          ? moment(this.selectedEvent.startTime.toDate()).format(
              'dddd, MMMM Do YYYY hh:mm A'
            )
          : ''
      },
      set() {}
    },
    computedEndTime: {
      get() {
        return this.selectedEvent.endTime
          ? moment(this.selectedEvent.endTime.toDate()).format('hh:mm A')
          : ''
      },
      set() {}
    }
  },
  methods: {
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0
    },
    scrollToTime() {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    },
    setDayView() {
      this.type = 'day'
    },
    next() {
      this.$refs.calendar.next()
    },
    prev() {
      this.$refs.calendar.prev()
    },
    viewHistory() {
      this.viewHistoryDialog = true
    },
    addAppointment() {
      this.addAppointmentDialog = true
      this.$emit('addAppointment')
    },
    closeAddAppt() {
      this.addAppointmentDialog = false
    },
    getEventColor(event) {
      return event.color
    },
    startCall(item) {
      this.$router.push(this.$route.query.redirect || '/room' + item)
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target

        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    async deleteEvent() {
      try {
        await fb.db
          .collection('Practices')
          .doc(this.partnerId)
          .collection('Appointments')
          .doc(this.selectedEvent.id)
          .delete()
        this.deleteDialog = false
      } catch (error) {
        console.log(error)
      }
    },
    getPatient() {
      if (this.selectedEvent.patientId) {
        let vitalStamp = null
        if (this.selectedEvent.vitalStamp) {
          vitalStamp = moment(this.selectedEvent.vitalStamp.toDate()).format(
            'MM/DD/YYYY HH:mm A'
          )
        }
        try {
          fb.patientsCollection
            .doc(this.selectedEvent.patientId)
            .get()
            .then(snap => {
              const data = snap.data()
              this.patient = {
                patientId: this.selectedEvent.patientId,
                uniqueName: this.selectedEvent.uniqueRoom,
                partnerId: this.partnerId,
                id: this.selectedEvent.id,
                lastUpdated: vitalStamp,
                isExamComplete: this.selectedEvent.isExamComplete,
                ...data
              }
            })
        } catch (error) {
          console.log(error)
        }
      }
    },
    getDoctor() {
      try {
        fb.proxiesCollection
          .doc(this.selectedEvent.doctorId)
          .get()
          .then(snap => {
            this.selectedDoctor = `Dr. ${snap.data().displayName}`
          })
      } catch (error) {
        console.log(error)
      }
    },
    openOrder(orderId) {
      let routeData = this.$router.resolve({
        path: `/order/${orderId}`
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss">
.v-current-time {
  height: 1px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-top: -3px;
    margin-left: -3px;
  }
}
</style>
